import request from '@/utils/request';

/* 爱的课堂模块 */

/* 爱的课堂背景图 */
export function getLoveClassBg(params) {
    return request({
        url: `/api/brandCampaign/classroom/background`,
        method: 'get',
        data: params
    })
}
/* 爱的课堂客服热线 */
export function customerPhone(params) {
    return request({
        url: `/api/common/customerPhone`,
        method: 'get',
        data: params
    })
}
/* 爱的课堂列表 */
export function getLoveClassList(params) {
    return request({
        url: `/api/brandCampaign/classroom/classroomList?type=${params.type}&pageNum=${params.pageNum}&pageSize=${params.pageSize}`,
        method: 'get',
        data: params
    })
}
/* 爱的课堂加入 */
export function joinLoveClass(params) {
    return request({
        url: `/api/brandCampaign/classroom/guidance`,
        method: 'post',
        data: params
    })
}
/* 爱的课堂详情 */
export function getLoveClassDetail(params) {
    return request({
        url: `/api/brandCampaign/classroom/classroomInfo?id=${params.id}`,
        method: 'get',
        data: params
    })
}

/* 家庭日模块 */

/* 家庭日背景图 */
export function getFamilyDayBg(params) {
    return request({
        url: `/api/brandCampaign/familyDay/background`,
        method: 'get',
        data: params
    })
}

/* 家庭日列表 */
export function getFamilyDayList(params) {
    return request({
        url: `/api/brandCampaign/familyDay/familyDayList?type=${params.type}&pageNum=${params.pageNum}&pageSize=${params.pageSize}`,
        method: 'get',
        data: params
    })
}
/* 家庭日详情 */
export function getFamilyDayDetail(params) {
    return request({
        url: `/api/brandCampaign/familyDay/familyDayInfo?id=${params.id}`,
        method: 'get',
        data: params
    })
}

/* 爱的分享模块 */

/* 爱的分享轮播图 */
export function getLoveShareBanner(params) {
    return request({
        url: `/api/brandCampaign/share/background`,
        method: 'get',
        params: params
    })
}

/* 爱的分享列表 */
export function getLoveShareList(params) {
    return request({
        url: `/api/brandCampaign/share/shareList?type=${params.type}&pageNum=${params.pageNum}&pageSize=${params.pageSize}`,
        method: 'get',
        data: params
    })
}
/* 爱的分享详情 */
export function getLoveShareDetail(params) {
    return request({
        url: `/api/brandCampaign/share/shareInfo?id=${params.id}`,
        method: 'get',
        data: params
    })
}


/* 爱力银行模块 */

/* 爱力银行轮播图 */
export function getBanner(params) {
    return request({
        url: `/api/brandCampaign/bank/background`,
        method: 'get',
        params: params
    })
}

/* 爱力银行 */
export function getAiliBank(params) {
    return request({
        url: `/api/brandCampaign/bank/bank`,
        method: 'get',
        data: params
    })
}

/* 爱力银行公益项目轮播图也是分类 */
export function getBanner2(params) {
    return request({
        url: `/api/brandCampaign/bank/assort?pageNum=${params.pageNum}&pageSize=${params.pageSize}`,
        method: 'get',
        data: params
    })
}

/* 爱力银行分类列表 */
export function getAiliBankList(params) {
    return request({
        url: `/api/brandCampaign/bank/psasList`,
        method: 'get',
        params: params
    })
}

/* 爱力银行分类详情 */
export function getAiliBankDetail(params) {
    return request({
        url: `/api/brandCampaign/bank/psasInfo?id=${params.id}`,
        method: 'get',
        data: params
    })
}

