import request from '@/utils/request';

/**以下是新接口对接**/
export function getHomeSlide(params={}) {
	params.isHome=true;
	return request({
		url: `/api/portal/slide/index`,
		method: 'post',
		data: params
	})
}
export function getHomeProduct(params={}) {
	params.isHome=true;
	return request({
		url: `/api/portal/slide/product`,
		method: 'post',
		data: params
	})
}
export function getLove(params={}) {
	params.isHome=true;
	return request({
		url: `https://www.aixingfugz.com/index.php?m=api&c=v1.Api&a=getObjectDetail&_ajax=1`,
		method: 'post',
		data: params
	})
}
export function getArchivesList(params={}) {
	params.isHome=true;
	return request({
		url: `https://www.aixingfugz.com/index.php?m=api&c=v1.Api&_ajax=1&a=archivesList`,
		method: 'post',
		data: params
	})
}
export function getActivityList(params={}) {
	params.isHome=true;
	return request({
		url: `https://www.aixingfugz.com/index.php?m=api&c=v1.Api&_ajax=1&a=getActivityList`,
		method: 'post',
		data: params
	})
}

