import axios from 'axios'
import qs from 'qs'
import Config from '@/settings'

const service = axios.create({
    // withCredentials: true,
    crossDomain: true,
    baseURL: process.env.NODE_ENV === 'production' ? process.env.VUE_APP_BASE_API : process.env.VUE_APP_BASE_API, // api 的 base_url
    timeout: Config.timeout // 请求超时时间
})

// request拦截器
service.interceptors.request.use(
    config => {
			console.log("config",config,config.data&&config.data.noStr)
			/*if(config.data&&config.data.isHome){
				config.url="http://admin.gzifami.com/"+config.url;
				delete config.data.isHome;
			}*/
			if(config.data&&!config.data.noSt){
				config.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'; 
			}else if (config.method === 'post') { // post请求时，处理数据
					config.data = qs.stringify({
							...config.data //后台数据接收这块需要以表单形式提交数据，而axios中post默认的提交是json数据,所以这里选用qs模块来处理数据，也有其他处理方式，但个人觉得这个方式最简单好用
					})
					config.headers['Content-Type'] = 'application/x-www-form-urlencoded'
			}
			
			return config
    },
    error => {
        // Do something with request error
        Promise.reject(error)
    }
)

// response 拦截器
service.interceptors.response.use(
    response => {
        const code = response.data.status;
        console.log("=============================" + code)
        if (code + '' === '401' || code + '' === '209') {
            console.log(response.data.msg);
            return;
        }
        if (code < 200 || code > 300) {
            console.log(response.data.msg);
            return Promise.reject('error')
        } else {
					if(response.data.result!==undefined&&!response.data.data){
						response.data.data=response.data.result;
						delete response.data.result;
					}
            return response.data
        }
    },
    (error) => {
        console.log("接口请求失败");
        
        return Promise.reject(error)
    }
)

export default service