<template>
    <div>
        <div class="banner">
            <img class="banner_img wow scaleStar" v-lazy="bgUrl" alt="" srcset="">
        </div>
        <div class="tabs">
            <div class="item" @click="currentIndex = 0" :class="{ 'itemCheck': currentIndex == 0 }">找女友</div>
            <div class="item" @click="currentIndex = 1" :class="{ 'itemCheck': currentIndex == 1 }">找男友</div>
        </div>
				<div class="list imgList">
						<div class="item" v-for="(item, index) in arcList" :key="index" @click="goto">
								<div class="box">
                        <img class="mainImg" v-lazy="item.images[0]" alt="" srcset="">
                    </div>
                    <div class="content">
                        <div class="title qoverDot2">{{ item.name }}</div>
												<div class="tip">{{item.age}}岁 / {{item.height}}cm{{item.constellation?" / "+item.constellation:''}}</div>
												<div>{{item.marriage}} / {{item.education}} / {{item.nation}}</div>
												<div>{{item.house_car}} / {{item.nativeArr[1]+item.nativeArr[2]}}</div>
                        <div class="time-btn">
                            <span class="time">{{ item.create_time }}</span>
                        </div>
                    </div>
						</div>
				</div>
       
    </div>
</template>

<script>
import { getFamilyDayBg, getFamilyDayList } from "@/api/brandActivity";
import {getLove} from "@/api/index";
export default {
    name: 'familyDay', //家庭日
    data() {
        return {
            bgUrl: '', //轮播图
            currentIndex: 0, //分类下标
            listAll: [[], []], //全列表数据
            // 每个选项卡对应的分页
            pages: [1, 1],  //页码
            pageSize: 6, //条数
            apiLoadStatus: [false, false], // api 加载状态记录
            noData: [false, false], //暂无数据
            totalPage: 0,  //总页数
            isMore: [false, false], //是否有更多
						arcList:[]
        };
    },
    mounted() {
        this.loadBg();
        this.loadList()
    },
    watch: {
        currentIndex: function (nVal, oldVal) {
            if (this.listAll[nVal] == null) {
                return;
            }
            if (this.listAll[nVal].length < 1) {
                this.pages[nVal] = 1
                this.loadList();
            }
        }
    },
    methods: {
        goDetail(id) {
            this.$router.push('/familyDayDetail?did='+id)
        },
        getMore() { //加载更多
            this.loadList()
        },
        loadList() {  //加载列表数据
					const t=this;
            var currentIndex = this.currentIndex;
            console.log('页数', this.pages[currentIndex]);
            if (this.apiLoadStatus[currentIndex]) return;
            this.apiLoadStatus[currentIndex] = true
            getLove({
								search: JSON.stringify({"sex":this.currentIndex==0?2:1}),
                current_page: this.pages[currentIndex],
                per_page: this.pageSize,
								noStr:true,
            }).then((res) => {
                console.log("res",res);
								if(res.code==1){
								let list=res.data.objects.data;
								/*let arr=[];
								for(let item of list){
									if(item.images){
									item.images=JSON.parse(item.images);
									}else{
									item.images=[]
									}
									
									arr.push({...item})
								}*/
								let {data} = t.dataFormat(list);
								console.log("datadata",data)
								t.arcList=data;
								}
								
            });
        },
        // 加载顶部背景图
        loadBg() {
            let that = this;
            getFamilyDayBg().then((res) => {
                if (res.code + '' === '200') {
                    this.bgUrl = res.data.image;
                    this.$nextTick(() => {
                        new this.$wow.WOW({ live: false }).init()
                    })
                }
            });
        },
				dataFormat(arr) {//相亲数据转换
					let dataArr = [];
					let imgArr = [];
					const eduArr = ["高中及以下", "中专", "大专", "本科", "硕士", "博士"];
					const sexArr = ["", "男", "女"];
					const marryArr = ["未婚", "离婚", "丧偶"];
					const houseArr = ["无房无车", "有房无车", "无房有车", "有房有车"];
					for (const item of arr) {
						let hobby = item.hobby;
						try {//捕抓异常，预防出错
							if (hobby) {
								hobby = JSON.parse(hobby);
							}
						} catch (e) {
							console.log("转换异常", e)
							hobby = ""
						}
						let character = item.character;
						try {//捕抓异常，预防出错
							if (character) {
								character = JSON.parse(character);
							}
						} catch (e) {
							console.log("转换异常", e)
							character = "";
						}
						let images = item.images;
						try {//捕抓异常，预防出错
							if (images) {
								images = JSON.parse(images);
							}
						} catch (e) {
							console.log("转换异常", e)
							images = "";
						}
						let age = this.calculateAge(item.birthdate);
						let obj = {
							...item,
							sex: sexArr[item.sex],
							sexIndex: item.sex,
							education: eduArr[item.education],
							eduIndex: item.education,
							marriage: marryArr[item.marriage],
							marryIndex: item.marriage,
							house_car: houseArr[item.house_car],
							houseIndex: item.house_car,
							nativeArr: item.native.split(","),
							native: item.native.replace(/,/g, ""),
							residenceArr: item.residence.split(","),
							residence: item.residence.replace(/,/g, ""),
							hobby,
							character,
							images,
							age,
							year: (item.birthdate.split("-"))[0]
						}
						dataArr.push(obj);
						imgArr.push(images && images[0] || "");
					}
					return {
						data: dataArr,
						img: imgArr
					};
				},
				calculateAge(birthDateString ) {
					var today = new Date();
					var birthDate = new Date(birthDateString.replace(/-/g, '/'));
					var age = today.getFullYear() - birthDate.getFullYear();
					var m = today.getMonth() - birthDate.getMonth();
					if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
						age--;
					}
					return age;
				},
				goto(){
					window.open("https://www.aixingfugz.com/love");
				}
    }
}
</script>
<style lang="scss" scoped>
.banner {
    position: relative;
    overflow: hidden;
    height: 680px;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

}

.tabs {
    display: flex;
    justify-content: center;
    margin: 80px 0 40px 0;

    .item {
        display: flex;
        justify-content: center;
        align-items: center;
        background: #f7f7f7;
        width: 163px;
        height: 62px;
        font-size: 19px;
        border-radius: 10px;
        cursor: pointer;
        transition: all .5s;

        &:first-child { 
            margin-right: 65px;
        }
    }

    .itemCheck {
        background: #eb6100;
        color: rgba(255, 255, 255, .8);
    }
}

.list {
    padding-bottom: 100px;
}

.more {
    margin: 0 auto;
    width: 190px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #5e5e5e;
    border-radius: 4px;
    font-size: 16px;
    margin-bottom: 138px;
}

.videoList {
    display: flex;
    flex-wrap: wrap;
    width: 1495px;
    margin: 0 auto;
    cursor: pointer;



    .item {
        width: 720px;
        margin-top: 60px;
        cursor: pointer;

        &:nth-child(2n) {
            margin-left: 44px;
        }

        .box {
            width: 720px;
            height: 457px;
            position: relative;
            overflow: hidden;
            border-radius: 10px;

            .mainImg {
                width: 100%;
                height: 100%;
                transition: all .5s;
                border-radius: 10px;
            }

            .boxShadow {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background: rgba(0, 0, 0, .6);
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 10px;

                .icon {
                    width: 100px;
                    height: 100px;
                    border-radius: 50%;
                }
            }
        }

        .content {
            width: 100%;
            height: 100px;
            box-sizing: border-box;
            padding: 28px 23px;
        }
    }

    .item:hover .mainImg {
        transform: scale(1.3);
    }

}

.imgList {
   display: flex;
    flex-wrap: wrap;
    width: 1495px;
    margin: 0 auto;



    .item {
        width: 480px;
        margin-top: 65px;
        margin-right: 22px;
        cursor: pointer;

        &:nth-child(3n) {
            margin-right: 0;
        }

        .box {
            width: 480px;
            height: 280px;
            position: relative;
            overflow: hidden;
            border-radius: 10px 10px 0 0;


            .mainImg {
                width: 100%;
                height: 100%;
                border-radius: 2px;
                transition: all .5s;
                border-radius: 10px 10px 0 0;
            }

            .boxShadow {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background: rgba(0, 0, 0, .6);
                display: flex;
                align-items: center;
                justify-content: center;

                .icon {
                    width: 100px;
                    height: 100px;
                    border-radius: 50%;
                }
            }
        }

        .content {
            width: 100%;
                box-sizing: border-box;
                padding: 28px 20px 20px 20px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                box-shadow: 3px 4px 6px #ccc;
                border-radius: 0 0 5px 5px;

                .title {
                    font-size: 20px;
                    height: 66px;
                }

                .time-btn {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    color: #666;
                    font-size: 14px;
                    height: 50px;

                    .btn {
                        font-size: 13px;
                        color: #666;
                    }
                }
        }
    }

    .item:hover .mainImg {
        transform: scale(1.3);
    }
}
</style>
